// src/LoginPage.js

import React, { useState, useEffect } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./firebaseConfig";
import { useNavigate } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  // Check if token exists in localStorage on component mount
  useEffect(() => {
    const checkAuth = async () => {
      const idToken = localStorage.getItem("idToken");

      if (!idToken) {
        return;
      }

      try {
        // Replace this URL with your server's token validation endpoint
        const response = await fetch(`${apiUrl}/validate-token`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`
          }
        });

        if (!response.ok) {
          throw new Error("Token validation failed");
        }

        const result = await response.json();

        if (result.valid) {
          // Token is valid, redirect to /upload
          navigate("/upload");
        } else {
          // Token is invalid, clear localStorage and redirect to login
          console.error(result);
          localStorage.removeItem("idToken");
        }
      } catch (error) {
        console.error("Error validating token:", error);
        // On error, clear localStorage and redirect to login
        localStorage.removeItem("idToken");
      }
    };
    checkAuth();
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const idToken = await userCredential.user.getIdToken();
      localStorage.setItem("idToken", idToken);
      // Redirect to a different page if needed
      navigate("/upload"); // Assuming you might want to navigate to a different page after login
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div>
      <h1>Login</h1>
      <form onSubmit={handleLogin}>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit">Login</button>
      </form>
      <p>
        Don't have an account? <a href="/signup">Sign up</a>
      </p>
      {message && <p>{message}</p>}
    </div>
  );
};

export default LoginPage;
