// src/UploadPage.js

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;

const UploadPage = () => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  // Check if token exists in localStorage on component mount
  useEffect(() => {
    const checkAuth = async () => {
      const idToken = localStorage.getItem("idToken");

      if (!idToken) {
        navigate("/");
      }

      try {
        // Replace this URL with your server's token validation endpoint
        const response = await fetch(`${apiUrl}/validate-token`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`
          }
        });

        if (!response.ok) {
          throw new Error("Token validation failed");
        }

        const result = await response.json();

        if (result.valid) {
          // Token is valid, redirect to /upload
          return;
        } else {
          // Token is invalid, clear localStorage and redirect to login
          console.error(result);
          localStorage.removeItem("idToken");
          navigate("/");
        }
      } catch (error) {
        console.error("Error validating token:", error);
        // On error, clear localStorage and redirect to login
        localStorage.removeItem("idToken");
        navigate("/");
      }
    };
    checkAuth();
  }, [navigate]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    const idToken = localStorage.getItem("idToken"); // Assuming token is saved in local storage

    if (!file) {
      setMessage("Please select a file.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(`${apiUrl}/upload`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`
        },
        body: formData
      });

      const data = await response.json();

      if (!response.ok) {
        if (response.status === 401) {
          // Handle unauthorized access
          navigate("/");
        }
      }
      setMessage(data.message);
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div>
      <h1>Upload Page</h1>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload File</button>
      {message && <p>{message}</p>}
    </div>
  );
};

export default UploadPage;
