// src/firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDJr7ZH5nfpTZSgfovhBraJBdaqXJIq-TI",
  authDomain: "cache-competition-backend.firebaseapp.com",
  projectId: "cache-competition-backend",
  storageBucket: "cache-competition-backend.appspot.com",
  messagingSenderId: "581904614119",
  appId: "1:581904614119:web:4f42b14750561c22ad9842",
  measurementId: "G-1HGN0HN9NT"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export { auth };
